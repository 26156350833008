.Container {
    align-items: center;
}

.StyledCard {
    overflow: unset !important;
    width: 50%;
    display: block;
}

.StyledTextArea {
    resize: none;
    overflow: hidden;
    outline: none;
    border: none;
    width: 100%;
    min-height: 30px;
    font-family: 'Roboto', serif;
    border-radius: 0.3rem;
}

.StyledSelect {
    margin-top: 5px;
    width: 25%;
    height: 30px;
    overflow: hidden;
    outline: none;
}

.ButtonContainer {
    margin-top: 8px;
    display: flex;
    align-items: center;
}

.StyledIcon {
    cursor: pointer;
}
