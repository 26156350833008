.createList {
    background-color: rgba(0, 0, 0, 0.15);
    height: 100%;
    width: 270px;
    padding: 1rem;
    border-radius: 0.3rem;
}

.createCard {
    height: 100%;
    margin-top: 10px;
}
