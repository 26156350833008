.filter {
    display: flex;
}

@media screen and (max-width: 510px) {
    .filter {
        display: block;
    }
    .filter-state {
        margin-left: 0 !important;
    }
}

.color-block {
    display: inline-block;
    width: 20px;
    height: 12px;
    margin-left: 5px;
    margin-right: 5px;
}

.filter-title {
    padding: 0;
    font-weight: bold;
}

#dropdown-basic-button {
    background-color: rgba(0, 0, 0, 0.15);
    border: none;
    font-family: 'Avenir', serif;
    color: white;
    font-size: 15px;
}

.dropdown-menu {
    padding: .5em;
}

input {
    margin-right: 5px;
}