.DisplayTitleCard {
    padding-left: 10px;
    padding-top: 10px;
}

.DisplayInfoCard {
    display: block;
    padding: 10px;
}

.priority {
    padding-left: 0 !important;
}

.left {
    float: left;
}

.right {
    position: relative;
    float: right;
    display: flex;
}