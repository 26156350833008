.ListContainer {
    background-color: rgba(0, 0, 0, 0.15);
    width: 270px;
    padding: 1rem;
    height: 100%;
    border-radius: 0.3rem;
}

.list-header {
    display: flex;
}

.StyledInput {
    width: 100%;
    border: none;
    outline-color: blue;
    margin-bottom: 3px;
    padding: 5px;
}

.TitleContainer {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', serif;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

