.StyledModalTitleElements {
    margin-top: 2%;
    display: flex;
    align-items: center;
}

.StyledModalHeader {
    font-size: 25px;
    font-weight: bold;
    margin-left: 4%;
    font-family: 'Roboto';
}

@media (max-width: 600px) {
    .StyledModalHeader {
        font-size: 20px;
    }
}

.StyledModalInfo {
    font-size: 17px;
    font-family: 'Roboto';
    cursor: pointer;
}

@media (max-width: 600px) {
    .StyledModalInfo {
        font-size: 14px;
    }
}

.StyledSubTitle {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 600px) {
    .StyledSubTitle {
        font-size: 16px;
    }
}

.DivCenter {
    display: flex;
    justify-content: center;
}

.StyledModalElements {
    margin-top: 15px;
    margin-left: 5%;
}