.all {
    overflow: scroll;
    height: 100%;
}

/** HEADER **/
.header {
    overflow: hidden;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    justify-content: space-between;
    margin-top: 0;
    padding: 0.5rem;
    display: flex;
}

.header-left {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.buttonDelete {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 20px;
}

#popover {
    font-family: 'Avenir', Serif;
}

#popover-header {
    font-size: 13px;
    color: #143E63;
    font-weight: bold;
    background-color: white !important;
    border: none;
}

#popover-body {
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.pb {
    border: none;
    cursor: pointer;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border-radius: 3px;
    width: 50px;
    padding: 0;
    margin: 5px;
}

.deleteBoard {
    background-color: #BB3025;
}

.closeDelete {
    background-color: #B2A4A3;
}

.BoardTitle {
    font-size: 23px;
    font-weight: bold;
    text-decoration: none;
    font-family: 'Roboto', serif;
    color: white;
    height: 100%;
    width: 100%;
    text-align: center;
}

.homeButtonStyle {
    text-decoration: none;
    font-family: 'Roboto', serif;
    color: white;
    height: 100%;
    margin-right: 10px;
    background-color: white;
}

/** BODY **/
.body {
    width: 100%;
    overflow: scroll;
    padding: 1rem;
}

.apiButton {
    background-color: white !important;
}

.list-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

@media (max-width: 800px) {
    .list-container{
        flex-direction: column;
        margin: 0;
        align-items: center;
    }
}

/** FOOTER **/
.modify-container {
    position: relative;
    padding: 1rem;
}

.modify {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.bg-color {
    position: relative;
    font-family: Helvetica, serif;
    float: right;
}

.filter {
    font-family: 'Avenir', serif;
    color: white;
    box-sizing: border-box;
}

