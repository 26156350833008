p {
    margin: 0;
    padding-left: 10px;
}

.input {
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    border: none;
    align-self: center;
}

.header-home {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0.5rem;
}

.create-title {
    font-size: 22px;
    background-color: rgba(0, 0, 0, 0.15);
    color: white;
    padding: 5px;
    margin-top: 20px;
    font-weight: lighter;
    font-family: 'Avenir';
}

.myBoards {
    color: white;
    font-family: Avenir;
    font-size: 22px;
}

.thumbnails {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    max-width: 1200px;
}

@media (max-width: 800px) {
    .thumbnails a {
        width: 100%;
    }

    .thumbnails a div{
        width: 100%;
    }
}

.boards {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 65%;
}

.navButton {
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 3px;
}

.navButton svg {
    font-size: 23px;
    color: white
}
