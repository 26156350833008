@import url(https://cdn.syncfusion.com/ej2/material.css);
.custom-select div {
    border: 0 !important;
    min-height: auto !important;
}

.DisplayTitleCard {
    padding-left: 10px;
    padding-top: 10px;
}

.DisplayInfoCard {
    display: block;
    padding: 10px;
}

.priority {
    padding-left: 0 !important;
}

.left {
    float: left;
}

.right {
    position: relative;
    float: right;
    display: flex;
}
#root {
    background-color: #EA3463;
}

* {
    box-sizing: border-box;
}

@media screen and (max-width: 400px) {
    .popup-content {
        width: 90% !important;
    }
}

@media screen and (min-width: 401px) and (max-width: 499px) {
    .popup-content {
        width: 85% !important;
    }
}

@media screen and (min-width: 500px) and (max-width: 550px) {
    .popup-content {
        width: 80% !important;
    }
}

@media screen and (min-width: 551px) and (max-width: 799px) {
    .popup-content {
        width: 70% !important;
    }
}

@media screen and (min-width: 800px) and (max-width: 999px) {
    .popup-content {
        width: 60% !important;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1150px) {
    .popup-content {
        width: 50% !important;
    }
}

@media screen and (min-width: 1151px) and (max-width: 1399px) {
    .popup-content {
        width: 45% !important;
    }
}

@media screen and (min-width: 1400px) {
    .popup-content {
        width: 30% !important;
    }
}

.react-datetime-picker {
    margin-left: 0;
    margin-top: 5px;
}

p {
    margin: 0;
    padding-left: 10px;
}

.input {
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    border: none;
    align-self: center;
}

.header-home {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0.5rem;
}

.create-title {
    font-size: 22px;
    background-color: rgba(0, 0, 0, 0.15);
    color: white;
    padding: 5px;
    margin-top: 20px;
    font-weight: lighter;
    font-family: 'Avenir';
}

.myBoards {
    color: white;
    font-family: Avenir;
    font-size: 22px;
}

.thumbnails {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem;
    max-width: 1200px;
}

@media (max-width: 800px) {
    .thumbnails a {
        width: 100%;
    }

    .thumbnails a div{
        width: 100%;
    }
}

.boards {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 65%;
}

.navButton {
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 3px;
}

.navButton svg {
    font-size: 23px;
    color: white
}

.all {
    overflow: scroll;
    height: 100%;
}

/** HEADER **/
.header {
    overflow: hidden;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    justify-content: space-between;
    margin-top: 0;
    padding: 0.5rem;
    display: flex;
}

.header-left {
    display: flex;
    align-items: center;
    width: 100%;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.buttonDelete {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 20px;
}

#popover {
    font-family: 'Avenir', Serif;
}

#popover-header {
    font-size: 13px;
    color: #143E63;
    font-weight: bold;
    background-color: white !important;
    border: none;
}

#popover-body {
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.pb {
    border: none;
    cursor: pointer;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border-radius: 3px;
    width: 50px;
    padding: 0;
    margin: 5px;
}

.deleteBoard {
    background-color: #BB3025;
}

.closeDelete {
    background-color: #B2A4A3;
}

.BoardTitle {
    font-size: 23px;
    font-weight: bold;
    text-decoration: none;
    font-family: 'Roboto', serif;
    color: white;
    height: 100%;
    width: 100%;
    text-align: center;
}

.homeButtonStyle {
    text-decoration: none;
    font-family: 'Roboto', serif;
    color: white;
    height: 100%;
    margin-right: 10px;
    background-color: white;
}

/** BODY **/
.body {
    width: 100%;
    overflow: scroll;
    padding: 1rem;
}

.apiButton {
    background-color: white !important;
}

.list-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
}

@media (max-width: 800px) {
    .list-container{
        flex-direction: column;
        margin: 0;
        align-items: center;
    }
}

/** FOOTER **/
.modify-container {
    position: relative;
    padding: 1rem;
}

.modify {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.bg-color {
    position: relative;
    font-family: Helvetica, serif;
    float: right;
}

.filter {
    font-family: 'Avenir', serif;
    color: white;
    box-sizing: border-box;
}


.StyledModalTitleElements {
    margin-top: 2%;
    display: flex;
    align-items: center;
}

.StyledModalHeader {
    font-size: 25px;
    font-weight: bold;
    margin-left: 4%;
    font-family: 'Roboto';
}

@media (max-width: 600px) {
    .StyledModalHeader {
        font-size: 20px;
    }
}

.StyledModalInfo {
    font-size: 17px;
    font-family: 'Roboto';
    cursor: pointer;
}

@media (max-width: 600px) {
    .StyledModalInfo {
        font-size: 14px;
    }
}

.StyledSubTitle {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 600px) {
    .StyledSubTitle {
        font-size: 16px;
    }
}

.DivCenter {
    display: flex;
    justify-content: center;
}

.StyledModalElements {
    margin-top: 15px;
    margin-left: 5%;
}
.ListContainer {
    background-color: rgba(0, 0, 0, 0.15);
    width: 270px;
    padding: 1rem;
    height: 100%;
    border-radius: 0.3rem;
}

.list-header {
    display: flex;
}

.StyledInput {
    width: 100%;
    border: none;
    outline-color: blue;
    margin-bottom: 3px;
    padding: 5px;
}

.TitleContainer {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', serif;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}


.Container {
    align-items: center;
}

.StyledCard {
    overflow: unset !important;
    width: 50%;
    display: block;
}

.StyledTextArea {
    resize: none;
    overflow: hidden;
    outline: none;
    border: none;
    width: 100%;
    min-height: 30px;
    font-family: 'Roboto', serif;
    border-radius: 0.3rem;
}

.StyledSelect {
    margin-top: 5px;
    width: 25%;
    height: 30px;
    overflow: hidden;
    outline: none;
}

.ButtonContainer {
    margin-top: 8px;
    display: flex;
    align-items: center;
}

.StyledIcon {
    cursor: pointer;
}

.filter {
    display: flex;
}

@media screen and (max-width: 510px) {
    .filter {
        display: block;
    }
    .filter-state {
        margin-left: 0 !important;
    }
}

.color-block {
    display: inline-block;
    width: 20px;
    height: 12px;
    margin-left: 5px;
    margin-right: 5px;
}

.filter-title {
    padding: 0;
    font-weight: bold;
}

#dropdown-basic-button {
    background-color: rgba(0, 0, 0, 0.15);
    border: none;
    font-family: 'Avenir', serif;
    color: white;
    font-size: 15px;
}

.dropdown-menu {
    padding: .5em;
}

input {
    margin-right: 5px;
}
.createList {
    background-color: rgba(0, 0, 0, 0.15);
    height: 100%;
    width: 270px;
    padding: 1rem;
    border-radius: 0.3rem;
}

.createCard {
    height: 100%;
    margin-top: 10px;
}

