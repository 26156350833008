@import url("https://cdn.syncfusion.com/ej2/material.css");

#root {
    background-color: #EA3463;
}

* {
    box-sizing: border-box;
}

@media screen and (max-width: 400px) {
    .popup-content {
        width: 90% !important;
    }
}

@media screen and (min-width: 401px) and (max-width: 499px) {
    .popup-content {
        width: 85% !important;
    }
}

@media screen and (min-width: 500px) and (max-width: 550px) {
    .popup-content {
        width: 80% !important;
    }
}

@media screen and (min-width: 551px) and (max-width: 799px) {
    .popup-content {
        width: 70% !important;
    }
}

@media screen and (min-width: 800px) and (max-width: 999px) {
    .popup-content {
        width: 60% !important;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1150px) {
    .popup-content {
        width: 50% !important;
    }
}

@media screen and (min-width: 1151px) and (max-width: 1399px) {
    .popup-content {
        width: 45% !important;
    }
}

@media screen and (min-width: 1400px) {
    .popup-content {
        width: 30% !important;
    }
}

.react-datetime-picker {
    margin-left: 0;
    margin-top: 5px;
}
